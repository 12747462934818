@import '../../../../scss/_variables.scss';

.employee-requests {
    .emp-request-table-cta-btns {

        .ant-radio-button-wrapper {
            width: 150px;
            text-align: center;
        }

        .ant-radio-button-wrapper-checked,
        .ant-radio-button-wrapper:hover {
            border-color: $color-primary !important;
            background-color: $color-zomp-border;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
            background-color: $color-primary !important;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
            color: $color-primary;
        }

        .ant-radio-button-wrapper,
        .ant-radio-button-wrapper::before {
            color: $color-primary;
            border-color: $color-primary !important;
        }

        .ant-radio-button-wrapper:first-child {
            border-radius: 4px 0 0 4px;
        }

        .ant-radio-button-wrapper:last-child {
            border-radius: 0 4px 4px 0;
        }
    }

    .empreq-table-cta {

        .table-cta {
            padding: 20px 0 0px 0 !important;

            .table-filter-row {
                .content {
                    padding-bottom: 4px;

                    &.active {
                        border-bottom: 2px solid var(--primary-color);
                    }
                }

                .ant-divider.ant-divider-vertical.show-on-web {
                    visibility: hidden;
                }
            }
        }
    }

    .ant-row.ant-row-space-between.ant-row-middle.table-cta.table-cta-wo-tabs {
        margin-bottom: 32px;
        border-bottom: 1px solid $color-table-bar;
    }

    .actions {
        display: flex;
        gap: 20px;

        .reject {
            color: $color-red-confirm !important;
        }

        .accept{
            color: $color-primary !important;
        }

        .undo {
            color: $color-hyperlink-blue !important;
            margin: 0 auto;
        }
    }
}