@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.retail-layout {

  .retail-header {
    border-bottom: 1px solid $retail-color-border;
    height: 96px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .retail-header-strip {
      // @include spacing(0, (auto 16px), 0, (auto 0px));

      .logo-image {
        width: 100%;
        max-height: 104px;

        @media screen and (max-width: 991px) {
          width: 80%;
        }
      }
    }

    @media screen and (max-width: 991px) {
      height: 72px;
    }

    .cta {
      max-width: 200px;
    }
  }
}