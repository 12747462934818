@import "../.../../../scss/_variables.scss";


.header-strip-parent {
    background-color: #ffffff;
    height: 80px;
    position: fixed;
    z-index: 999;
    width: 100%;

    .header-strip {
        width: 100%;
        height: 80px;
        // background-color: var(--primary-color-light) !important;
        background-color: #fff;
        border-bottom: 0.5px solid var(--base-grey-400, #93959B);
        opacity: 1;
        padding: 0 32px;
        color: white;
        backdrop-filter: blur(32px);

        .title {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
        }

        .back-button {
            margin-right: 8px;
            height: 24px;
            width: 24px;
            cursor: pointer;
        }

        @media screen and (max-width: 991px) {
            // background-color: var(--primary-color-light) !important;
            background-color: #fff;
        }

        .header-logo {
            // filter: brightness(0) invert(1);
            max-width: 178px;
            max-height: 64px;
            height: auto;
            width: auto;
        }
    }
}

.love-badge {
    .we {
        color: var(--primary-text-default, #4B5054);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    .organisation {
        color: var(--primary-text-default, #4B5054);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
}

.extend-love-badge {
    background: #fff;
    padding: 7px 0px;
    // stroke: var(--base-grey-300, #C9CACD);
    // stroke-width: 0.5px;
    border: 0.5px solid $color-base-grey;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;

    .we {
        color: var(--primary-text-default, #4B5054);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    .heart {
        margin: 0px 4px;
    }

    .organisation {
        color: var(--base-grey-800, #383E42);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
    }
}

.header-strip-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-primary-text !important;
    //     color: #F6F7F9;

    &.pipe {
        opacity: 0.5;
        font-size: 24px;
    }
}

.header-menu {
    padding: 8px 0px;
    background: #FFFFFF;
    border: 1px solid #C9CACD;
    box-shadow: 0px 0px 32px rgba(62, 73, 70, 0.3);
    border-radius: 4px;
    z-index: 999;

    .item {
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
            background-color: var(--primary-color-light);
        }
    }
}

.header-web {
    @media screen and (max-width: 991px) {
        display: none;
    }
}

.header-mobile {
    display: none;

    @media screen and (max-width: 991px) {
        display: block;
    }
}