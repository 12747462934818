@import '../../scss/_variables.scss';


@keyframes shine {
    0% {
        left: -10px
    }

    20% {
        left: 20%
    }

    100% {
        left: 80%
    }
}

.primary {
    color: var(--primary-color);
}

.navbar {
    position: fixed;
    background-color: white;
    padding-top: 48px;
    min-height: calc(100vh - 80px);
    max-width: 224px;
    width: 100%;
    transform: width 0.3s ease-in-out !important;

    @media screen and (min-width: 992px) and (max-width: 1440px) {
        max-width: 104px;
    }


    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 32px;
        background-color: white;
        width: inherit;

        @media screen and (max-width: 1440px) {
            display: none;
        }
    }

    .navbar-toggle {
        margin: 20px 0;
    }

    .navbar-item {
        margin: 4px 12px;
        cursor: pointer;
        padding: 12px 0px 12px 12px;
        background-color: inherit;
        color: inherit;
        border-radius: 4px;

        .offer-tag {
            background-color: var(--primary-color-light);
            color: var(--primary-color);
            margin-left: 8px;
            border-radius: 8px;
            padding: 0px 16px;
        }

        @media screen and (min-width: 992px) and (max-width: 1440px) {
            padding: 10px 0px;
        }
    }

    .navbar-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-left: 8.62px;
    }

    .nav-link {
        color: black;

        @media screen and (min-width: 992px) and (max-width: 1440px) {
            text-align: center;
        }

        &.highlightedx {
            background-color: var(--primary-color);
            // background-color: #aaa9ad;
            // #f2cb67 #29165e
            color: white;

            &.box {
                position: relative;
                overflow: hidden;
            }

            &.box:before {
                content: '';
                position: absolute;
                overflow: hidden;
                width: 70px;
                height: 100%;
                background-image: linear-gradient(120deg,
                        rgba(255, 255, 255, 0) 30%,
                        rgba(255, 255, 255, .8),
                        rgba(255, 255, 255, 0) 70%);
                top: 0;
                // left: -50px;
                animation: shine 2s infinite linear;
                /* Animation */
            }
        }

        &:hover {
            color: white;
            background-color: var(--primary-color);
            transition: all 0.3s ease-in-out;

            .offer-icon {
                color: white;
            }

            .offer-tag {
                color: white;
            }
        }

        &.active {
            color: white;
            background-color: var(--primary-color);
        }
    }
}

.nav-page {
    @media screen and (min-width: 992px) and (max-width: 1440px) {
        display: none;
    }
}

.navbar-mobile-icon svg path {
    cursor: pointer;
    fill: var(--text-color);
}

.navbar-mobile {
    height: 100vh;
    background-color: white;
    position: fixed;
    width: 100%;
    z-index: 999;

    &.slide-in {
        -webkit-animation: slideIn 1s forwards;
        -moz-animation: slideIn 1s forwards;
        animation: slideIn 1s forwards;

        @-webkit-keyframes slideIn {
            0% {
                transform: translateX(991px);
            }

            100% {
                transform: translateX(0);
            }
        }

        @-moz-keyframes slideIn {
            0% {
                transform: translateX(90px);
            }

            100% {
                transform: translateX(0);
            }
        }

        @keyframes slideIn {
            0% {
                transform: translateX(991px);
            }

            100% {
                transform: translateX(0);
            }
        }
    }

    // &.slide-out {
    -webkit-animation: slideOut 1s forwards;
    -moz-animation: slideOut 1s forwards;
    animation: slideOut 1s forwards;

    @-webkit-keyframes slideOut {
        0% {
            transform: translateX(0px);
        }

        100% {
            transform: translateX(991px);
        }
    }

    @-moz-keyframes slideOut {
        0% {
            transform: translateX(0px);
        }

        100% {
            transform: translateX(991px);
        }
    }

    @keyframes slideOut {
        0% {
            transform: translateX(0px);
        }

        100% {
            transform: translateX(991px);
        }
    }

    // }

    .navbar-header {
        padding: 0 32px;
        // background-color: #222933;
        background-color: var(--primary-color-light) !important;
        height: 80px;
    }

    .navbar-profile {
        padding: 0 32px;
        margin-top: 60px;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #4B5054;
    }

    .navbar-divider {
        margin: 24px 32px;
        border: 0.5px solid #E9E9EA;
    }

    .navbar-menu {
        padding: 0 32px;
    }


    .navbar-toggle {
        padding: 0 32px;

        .navbar-toggle-text {
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #4B5054;
        }

        .navbar-toggle-switch {
            .ant-switch {
                background-color: #F5F5F5;
                margin: 0 8px;

                .ant-switch-handle::before {
                    background-color: var(--primary-color);
                }
            }

            .ant-switch-checked {
                background-color: var(--primary-color);

                .ant-switch-handle::before {
                    background-color: white;
                }
            }
        }
    }

    .navbar-menu-item {
        color: #4B5054;

        &:hover,
        &.active {
            color: var(--primary-color);
        }

        .navbar-menu-item-group {
            padding: 12px 0;
            margin: 4px 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: inherit;

            .navbar-menu-item-icon {
                margin-right: 12px;
            }
        }

    }

    .navbar-footer {
        padding: 0 32px;
        position: absolute;
        bottom: 48px;
        left: 0;
        right: 0;
    }
}

.navbar-footer {
    position: fixed;
    border-top: 0.5px solid #C9CACD;
    bottom: 0;
    background-color: white;
    width: 100%;
    // height: 75px;

    .navbar-footer-button {
        font: normal 600 12px 'Inter';
        color: $color-primary-text;
        width: 100%;
        text-align: center;
        border-top: 2px solid transparent;
        padding: 12px 0;

        &:hover {
            color: $color-primary !important;
            border-top: 2px solid $color-primary;
        }
    }

    .active {
        .navbar-footer-button {
            color: $color-primary !important;
            border-top: 2px solid $color-primary;
        }
    }


    .navbar-footer-button-icon {
        // margin-bottom: 6px;
    }
}