@import '../../../scss/variables.scss';

.tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 16px;
    text-transform: uppercase;
    border-radius: 100px;
    // margin-right: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.08em;
    width: fit-content;
    // max-width: 150px;

    &.success {
        background-color: rgba(45, 162, 79, 0.1);
        color: #2DA24F;
    }

    &.fail {
        background-color: rgba(235, 98, 98, 0.1);
        color: #EB6262;
    }

    &.pending {
        background-color: rgba(34, 122, 229, 0.1);
        color: #3984DA;
    }

    &.invited {
        background-color: rgba(239, 169, 52, 0.1);
        color: #EFA934;
    }

    &.addition {
        background-color: rgba(34, 122, 229, 0.1);
        color: #3984DA;
    }

    &.deletion {
        background-color: rgba(34, 122, 229, 0.1);
        color: #3984DA;
    }
}

@media screen and (max-width: 991px) {
    .tag {
        font-size: 8px;
        min-width: 100px;
    }
}