@import '../../scss/_variables.scss';
@import '../../scss/_mixins.scss';

.modal-card {
    padding: 16px;
    text-align: center;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
    padding: 0;
}

.modal-stats {
    font-size: 24px;
    font-weight: 500;
    color: var(--primary-color);
}

.modal-icon {
    font-size: 50px;
    text-align: center;
    margin-top: 16px;
}

.modal-title {
    font-size: 20px;
    font-weight: 600;
    color: $color-black;
    line-height: 28px;
    // margin-top: 20px;
}

.member-modal {
    .product-name {
        font-size: 16px;
        font-weight: 500;
        color: $color-black;
        line-height: 24px;
    }
}


.modal-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: $color-red-input-error;
    line-height: 12px;
    margin: 8px 0 8px 0;
}

.avail-subscription-modal {

    .subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    .steps {
        font-family: 'Inter', sans-serif !important;
        max-width: 512px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .divider {
        margin-top: 16px;
        margin-bottom: 24px;
    }


    .steps-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $color-primary-text;
        margin-bottom: 24px;
    }

    .steps>ol {
        margin: 4px !important;
        padding-left: 12px !important;
        // list-style-position: inside !important;
    }

    .steps>ol>li {
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 19px !important;
        color: #302D3C !important;
        margin: 0 0 2px 0 !important;
    }

    .powered-by {
        margin: 0px 10px 0 0;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #5A6474;
        text-transform: uppercase;
    }

    .provider-logo {
        margin-left: 8px;
        max-width: 80px;
        max-height: 80px;
    }

    .generate-coupon {
        cursor: pointer;
        margin-bottom: 24px;
        padding-top: 16px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        height: 56px;
        width: 160px;
        background-image: url("../../assets/images/generate_coupon.svg");
        background-repeat: no-repeat;
        background-position: center;
        color: #4B5054;

    }

    @media screen and (max-width: 991px) {
        .steps {
            margin-top: 24px;
        }
    }
}

.coupon-code {
    padding: 18px;
    margin-bottom: 24px;
    border: 0.5px dashed var(--primary-color);
    background-color: #fafafa;
    width: max-content;

    .coupon-code-value {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: $retail-color-primary-text;
    }

    .copy-coupon-code {
        margin-left: 10px;
        color: var(--primary-color);
    }
}

.confirm-bulk-addition {
    .modal-title {
        font-size: 32px;
        font-weight: 600;
        color: $color-primary-text;
        line-height: 36px;
    }

    .modal-subtitle {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: $color-primary-text;
        margin-top: 72px;
    }
}

.edit-uhi-member {
    .title {
        @include text(20px, $color-primary-text, 500, 28px);
    }
}

.contact-modal {
    .ant-modal-body {
        padding: 32px 24px !important;
    }

    .title {
        // margin-top: 32px;
        @include text(20px, $color-primary, 700, 28px);
        color: var(--primary-color);
    }

    .subtitle {
        margin-top: 16px;
        @include text(16px, $color-primary-text, 500, 24px);
    }

    .item {
        // margin-top: 8px;
        @include text(16px, $color-primary-text, 400, 24px);
    }
}

.claim-documents-modal {
    // padding: 58px 64px;

    .documents {
        @media screen and (min-width: 991px) {
            max-height: 50vh;
            overflow-y: scroll;
            // margin-top: 32px;
        }

    }

    .close-cta {
        float: right;

        .close-icon {
            color: $color-primary-text;
            width: 16px;
        }
    }

    .title {
        @include text(20px, $color-primary-text, 600, 28px);
    }

    .subtitle {
        @include text(12px, $color-secondary-text, 400, 16px);
        margin: 0px;
    }

    .doc-box {
        padding: 24px 36px;
        margin-top: 16px;
        background-color: #FAFAFA;
        border-radius: 4px;

        .doc-title {
            @include text(16px, $color-primary-text, 500, 24px);
        }

        .doc-point {
            margin-top: 8px;

            .doc-title,
            .doc-key {
                @include text(14px, $color-primary-text, 500, 20px);
            }

            .doc-value {
                @include text(14px, $color-primary-text, 400, 20px);
            }

            li {
                margin-bottom: 8px;
            }
        }
    }
}