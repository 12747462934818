@import '../../../scss/variables.scss';

.to-dashboard {
    gap: 8px;
    padding-left: 32px;
    padding-right: 32px;
    background-color: $color-info-light;
    height: 40px;
    align-items: center;

    .link {
        color: $color-process-blue !important;
        font-size: 14px !important;

        &:hover {
            color: $color-process-blue !important;
            opacity: 0.8;
        }
    }

    .back {
        margin-right: 8px;
    }

    @media screen and (max-width: 993px) {
        display: flex;
    }

    @media screen and (min-width: 993px) {
        display: none;
    }

}