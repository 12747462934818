@import '../../scss/variables.scss';

.header-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.3px;
  padding: 56px 0 16px 0;

  @media screen and (max-width: 991px) {
    padding: 60px 0 16px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

}

.hamburger-menu {
  color: var(--primary-color);
  padding: 30px 0 20px 0;
  line-height: 29px;
  font-size: large;
}

.nav-drawer {
  padding: 0;
  margin: 0;

  .ant-drawer-body {
    padding: 0 12px;
    margin: 0;
  }

  .ant-drawer-header {
    border: none;
    padding-bottom: 0;
  }
}