@import '../../../scss/variables.scss';

.contact-card {
    border-radius: 8px;
    padding: 24px 12px;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0.25) 0%, var(--primary-color-light) 100%);
    margin: 0px 12px;

    .description {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $color-primary-text;
        text-align: start;
    }

    .divider {
        height: 1px;
        width: 32px;
        background-color: var(--primary-color);
        margin: 16px 0px;
    }

    .relationship-manager-card {

        .title {
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            margin-bottom: 4px;
            color: $color-secondary-text;
            text-align: start;
        }

        .details {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $color-primary-text;
            text-align: start;
            overflow-wrap: break-word;
        }
    }
}

// .contact-card-mobile {
//     padding: 16px;
//     background-color: white;
//     border-radius: 8px;
//     margin-top: 24px;

//     .title {
//         font-family: 'Inter';
//         font-style: normal;
//         font-weight: 500;
//         font-size: 14px;
//         line-height: 17px;
//         margin-bottom: 4px;
//         color: $color-black;
//     }

//     .details {
//         font-family: 'Inter';
//         font-style: normal;
//         font-weight: 400;
//         font-size: 12px;
//         line-height: 15px;
//         margin: 2px 0;
//         color: $color-black;
//     }
// }


.float {
    position: fixed;
    bottom: 0px !important;
    right: 0px !important;
    opacity: 1;
    z-index: 999;
    display: none;

    // box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 1440px) {
        display: block;
    }
}

.float-1 {
    path {
        fill: var(--primary-color);
    }
}

.mobile-contact-card {
    padding: 0px;
    margin-top: 22px;
    margin-bottom: 22px;
    display: none;

    @media screen and (max-width: 1440px) {
        display: block;
    }


    .title {
        color: $color-primary-text;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
    }

    .subtitle {
        margin-top: 12px;
        color: $color-primary-text;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .cta {
        margin-top: 16px;
    }
}